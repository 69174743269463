.disclaimer-component {
    color: #322e2e;
    font-size: 0.9em;
    font-weight: bold;
    padding: 10px;
    background-color: #eff1f5;
    border-radius: 5px;
    text-align: left;
    margin-bottom: 10px;
}

.disclaimer-component.as-card {
    border-color: rgb(205, 212, 217);
    border-style: solid;
    border-width: 1px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(227, 232, 236, 1);
}

@media (max-width: 768px) {
    .disclaimer-component {
        text-align: center;
    }
}

.disclaimer-component.full {
    width: 100%;
}

@media (min-width: 1500px) {
    .disclaimer-component {
        margin-right: 10vw;
    }
    .disclaimer-component.full {
        margin-right: 0;
    }
}
