.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(235, 247, 255, 1);
}

.ant-menu-item-selected {
  color: rgba(0, 52, 91, 1);
  font-weight: bold;
}

.ant-menu-inline .ant-menu-item::after {
  border-right: 4px solid rgba(0, 52, 91, 1);
}

.ant-layout {
  background-color: transparent;
}

.ant-layout-content {
  padding: 0px 50px;
}

.ant-layout-sider {
  background-color: transparent;
  border-right: 1px solid rgb(236, 241, 245);
  padding-right: 5px;
  height: 100%;
}

.ant-page-header-heading-title {
  color: rgba(0, 52, 91, 1);
}

.ant-typography {
  color: rgba(0, 52, 91, 1);
}

.content {
  height:auto !important;
  min-height:100%;
}

.list-item {
  border-top: 1px dashed rgba(0, 52, 91, 1);
  height: auto !important;
}

@media (max-width: 600px) {
  .hide-responsive {
    display: none;
  }
  .ant-layout-sider {
    background-color: rgb(255, 255, 255) !important;
    position: absolute;
    z-index: 999;
  }
}
@media (min-width: 600px) {
  .show-responsive {
    display: none;
  }
}