.header-title-big .ant-card-head {
  font-size: 20px;
}

.header-title-medium .ant-card-head {
  font-size: 16px;
}

.data-box {
  margin: 10px 0;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(227, 232, 236, 1);
}

.data-box .ant-card-head {
  color: rgba(0, 52, 91, 1);
  font-size: 16px;
}

.data-box .ant-card-body {
  padding-top: 5px;
}

.icon-card {
  color: rgba(205, 83, 52, 1);
  font-size: 30px;
}

@media (max-width: 600px) {
  .avatar-person {
    display: none;
  }
}