@media (max-width: 900px) {
    .ddjj .ant-card-body {
        overflow-x: scroll;
    }

    .ddjj.ant-card-body::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    .ddjj .ant-card-body::-webkit-scrollbar:vertical {
        width: 11px;
    }

    .ddjj .ant-card-body::-webkit-scrollbar:horizontal {
        height: 11px;
    }

    .ddjj .ant-card-body::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white; /* should match background, can't be transparent */
        background-color: rgba(0, 0, 0, .5);
    }

}
