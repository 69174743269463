#header {
  background-color: #fff;
  position: relative;
  z-index: 10;
  min-height: 64px;
}
.dropdown-item .anticon {
  color: rgba(0, 52, 91, 1);
  font-size: larger;
}


.header-title {
  color: rgba(0, 52, 91, 1);
  overflow: hidden;
  text-align: center;
  line-height: 64px;
  text-decoration: none;
  height: 64px;
}
.header-table-mode {
  color: rgba(0, 52, 91, 1);
  font-size: 20px;
  text-align: center;
}
.header-title-wrapper {
  width: 300px;
  /*border-right: 1px solid rgb(236, 241, 245);*/
  display: inline-block;
  vertical-align: middle;
  line-height: 64px;
}

.header-search-wrapper {
  padding-left: 40px;
  vertical-align: middle;
  line-height: 64px;
}


.header-meta {
  color: rgba(0, 52, 91, 1);
  padding-right: 40px;
}

.collapsed-menu {
  color: rgba(0, 52, 91, 1);
  padding-right: 40px;
  padding-top: 20px;
}

#menu {
  float: right;
  overflow: hidden;
  height: 64px;
}
#menu .ant-menu {
  line-height: 60px;
}
#menu .ant-menu-horizontal {
  border-bottom: none;
}
#menu .ant-menu-horizontal > .ant-menu-item {
  border-top: 2px solid transparent;
}
#menu .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom: 2px solid rgba(0, 52, 91, 1);
  border-top: 2px solid transparent;
}
#menu .ant-menu-horizontal > .ant-menu-item a {
  color: rgba(0, 52, 91, 1);
}
#menu .ant-menu-horizontal > .ant-menu-item-selected {
  border-bottom: 2px solid rgba(0, 52, 91, 1);
  border-top: 2px solid transparent;
}
#menu .ant-menu-horizontal > .ant-menu-item-selected a {
  color: rgba(0, 52, 91, 1);
}

.menu-item {
  color: rgba(0, 52, 91, 1) !important;
}

.control-logo {
  width: 200px;
  height: 70px;
}

.ant-input-search-icon {
  color: rgba(0, 52, 91, 1);
}

.ant-input-affix-wrapper:hover {
  border: solid 1px rgba(0, 52, 91, 1);
}

.header-shadow {
  box-shadow: 0px 10px 5px rgba(42, 42, 42, 0.06);
}
