
.action-landing {
    padding: 20px;
    background-image: url('../assets/imgs/background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: center;
}

.action-landing .title {
    justify-content: center;
    align-items: center;
    font-size: 45px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 20px;
    color: rgba(0, 52, 91, 1);
}

.action-landing .description {
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin-left: 20vw;
    margin-right: 20vw;
}

@media (max-width: 768px) {
    .action-landing .description {
        margin-left: 2vw;
        margin-right: 2vw;
    }
}

.action-landing .description > div {
    text-align: center;
    padding: 10px;
}

.cards .ant-card-meta-title {
    overflow: visible;
    white-space: normal;
    text-overflow: unset;
}

.action-landing .cards {
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-left: 4vw;
    padding-right: 4vw;
}

.action-landing .cards .ant-card {
    margin: auto;
    box-shadow: 0px 5px 15px rgba(42, 42, 42, 0.06);
    border-radius: 20px;
}
.ant-card-cover {
    padding-top: 15px;
}
.ant-card-img {
    height: 80px;
}

.ant-card-meta-detail {
    text-align: center;
}

.ant-card-meta-title {
    margin-bottom: 25px;
    color: rgba(0, 52, 91, 1);
}

.ant-card-meta-description {
    color: rgba(0, 52, 91, 1);
}

.action-landing .footer {
    padding-top: 40px;
}
