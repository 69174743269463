footer.dark {
  background-color: #000;
  color: rgba(255, 255, 255, 0.65);
}
footer.dark a {
  color: #fff;
}
footer.dark h2 {
  color: rgba(255, 255, 255, 1);
}
footer.dark h2 > span {
  color: rgba(255, 255, 255, 1);
}
footer.dark .bottom-bar {
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  overflow: hidden;
}
footer {
  margin-top: auto;
}
footer .ant-row {
  text-align: center;
}
footer .ant-row .footer-center {
  display: inline-block;
  text-align: left;
}
footer .ant-row .footer-center > h2 {
  font-size: 14px;
  margin: 0 auto 24px;
  font-weight: 500;
  position: relative;
}
footer .ant-row .footer-center > h2 > .anticon {
  font-size: 16px;
  position: absolute;
  left: -22px;
  top: 3px;
  color: #aaa;
}
footer .ant-row .footer-center > div {
  margin: 12px 0;
}
footer .footer-wrap {
  position: relative;
  padding: 86px 114px 70px 114px;
}
footer .bottom-bar {
  text-align: right;
  padding: 20px 114px;
  margin: 0;
  line-height: 24px;
}
footer .bottom-bar a {
  color: rgba(255, 255, 255, 0.65);
}
footer .bottom-bar a:hover {
  color: #fff;
}
footer .bottom-bar .translate-button {
  text-align: left;
  width: 200px;
  margin: 0 auto;
}
footer .footer-logo {
  position: relative;
  top: -2px;
}
footer .footer-flag {
  position: relative;
  top: -4px;
  margin-right: 8px;
}
.footer-background {
  background-color: rgba(0, 52, 89, 1);
  padding: 5px 10%;
  text-align: justify !important;
}
.footer-background-secondary {
  background-color: rgba(223, 237, 251, 1);
  padding: 5px 10%;
}
.footer-paragraph {
  color: rgba(153, 173, 188, 1) !important;
}
.footer-logo-wrapper {
  display: inline-block;
  margin: 0 5px;
}
.logo-small {
  height: 30px;
  width: 45px;
}
.footer-label {
  color: rgba(0, 52, 91, 1);
}
#logo {
  overflow: hidden;
  line-height: 64px;
  text-decoration: none;
  margin-bottom: 15px;
}
#logo img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
  width: 15%;
}
#logo span {
  color: rgba(0, 52, 91, 1);
  outline: none;
  font-size: 14px;
  line-height: 28px;
}
@media only screen and (min-width: 600px) {
  .copyright {
    text-align: left;
  }

  .repo {
    text-align: right;
  }
}
@media only screen and (max-width: 600px) {
  #logo img{
    width: 20%;
  }
  .repo {
    text-align: center;
  }
  .copyright{
    text-align: center;
  }
}

.footer-paragraph {
  text-align: center;
}

#logo .footer-logo-impulsor {
  width: 100%;
}

@media screen and (max-width: 767px) {
  #logo .footer-logo-impulsor {
    width: 60%;
  }
}

@media screen and (min-width: 1800px) {
  #logo .footer-logo-impulsor {
    width: 70%;
  }
}