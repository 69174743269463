.ant-carousel .slick-dots li button {
    background: #364D79;
    opacity: 0.4;
}

.ant-carousel .slick-dots li.slick-active button {
    opacity: 1;
    background: #364D79;
}

.search-button .ant-input {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.search-button .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {

    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
