.ant-descriptions-item-content.align-right-desc {
    text-align: right;
}

.custom-card {
    transition: transform 0.3s, box-shadow 0.3s;
    color: #868e96;
    display: inline-block;
    width: 100%;
    /*padding: 32px;*/
    box-shadow: 10px 10px 5px rgba(84, 48, 132, 0.06);
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    text-align: center;
    border-radius: 20px;
}

.custom-card-no-shadow {
    transition: transform 0.3s, box-shadow 0.3s;
    color: #868e96;
    display: inline-block;
    width: 100%;
    box-shadow: unset;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    text-align: center;
    border-radius: 20px;
}

.left-align {
    text-align: left !important;
}

.custom-shadow-small {
    box-shadow: 5px 5px 2px rgba(84, 48, 132, 0.06);
}

.round-button {
    font-size: 12px;
    min-width: 100px;
    min-height: 30px;
    text-transform: none !important;
    padding: 0;
    background-color: #CD5534;
    color: white;
    border-radius: 1rem;
    border: none;
    margin-right: 5px;
    cursor: pointer;
}

.title-color {
    color: #003459 !important;
}
